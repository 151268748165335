import React from "react";

import SEO from "../components/SEO";
import PointsFeatureSection from "../components/partials/points/PointsFeatureSection";
import PointsCtaSection from "../components/partials/points/PointsCtaSection";
import HeroSection from "../components/partials/points/HeroSection";
import QuotesSection from "../components/partials/points/QuotesSection";

const IndexPage = () => {
  return (
    <>
      <SEO
        title="Points"
        description="Share positivity with Points! A quick and easy way to keep track of how you're cheering on students successes to esure you're reaching all your learners."
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "Points",
          description:
            "Share positivity with Points! A quick and easy way to keep track of how you're cheering on students successes to esure you're reaching all your learners.",
        }}
      />
      <HeroSection />
      <QuotesSection />
      <PointsFeatureSection />
      <PointsCtaSection />
    </>
  );
};
export default IndexPage;
